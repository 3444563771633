import 'promise-polyfill/src/polyfill' // Promises need to be polyfilled for Axios to work in IE 11 or below
import Vue from 'vue/dist/vue.esm'
import Axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, Axios)

document.addEventListener('DOMContentLoaded', () => {
  Vue.axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').content
})

export default Vue
