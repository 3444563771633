<template>
  <button @click="$emit('click', value)" class="donate-btn" :class="getClasses" selected=":isSelected">
    <slot>
      ${{ amount }}
    </slot>
  </button>
</template>

<script>
export default {
  props: ['value', 'selected', 'classes'],
  data: function () {
    return {}
  },
  computed: {
    amount: function() {
      return Intl.NumberFormat('en-US').format(this.value)
    },
    getClasses: function() {
      return {
        selected: (this.value == this.selected),
        ...this.classes
      }
    }
  },
}
</script>

<style scoped>
.donate-btn {
  @apply
    mb-2
    px-8
    py-4
    rounded-lg;
}
</style>