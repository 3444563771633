<template>
  <transition name="fade" mode="out-in">
    <div id="subscribe-prompt" v-if="showPrompt">
      <div class="bg-gray-900 shadow-2xl block w-auto z-50 shadow-2xl relative border-t-2 border-gray-400">
        <div @click="dismissPrompt" class="self-center bg-red-700 hover:bg-red-800 py-1 px-4 mx-1 rounded-lg shadow-md border-2 border-red-500 text-white text-center text-lg slab block absolute top-0 right-0 cursor-pointer" style="top:-20px; right:10px;">X</div>
        <div class="p-6 container mx-auto">
          <div v-if="!finished" key="form">
            <div class="text-2xl md:text-4xl text-gray-100 slab text-center mb-1">
              <slot name="title"></slot>
            </div>
            <div class="text-xl sm:text-2xl text-gray-100 text-center mb-0">
              <slot name="copy"></slot>
            </div>
            <div class="flex flex-wrap content-center items-center justify-center text-center text-md md:text-2xl slab">
              <input v-model="email" v-on:keyup="keyUp = true" v-on:change="validateEmail" type="text" placeholder="Email address" class="reset px-2 py-1 md:px-6 md:py-3 rounded-l-lg rounded-r-none border-t-2 border-l-2 border-b-2 border-gray mt-4 w-auto">
              <button @click="clicked" class="self-center bg-green-700 hover:bg-green-800 px-2 py-1 md:py-3 md:px-6 md:mr-1 rounded-r-lg shadow-md border-2 border-green-500 mt-4 text-white">Subscribe</button>
              <a @click="currentSubscriber" class="self-center bg-red-700 hover:bg-red-800 px-2 py-1 md:py-3 md:px-6 mx-1 mt-4 rounded-lg shadow-md border-2 border-red-500 text-white">I'm Already Subscribed</a>
            </div>
            <transition name="fade" mode="out-in">
              <div v-if="error" key="error" class="alert alert-error mt-4 mb-0 px-3 py-2 text-sm w-full sm:w-2/3 mx-auto">{{ error }}</div>
            </transition>
          </div>
          <div v-else key="success">
            <div class="w-full md:w-2/3 mx-auto">
              <div class="alert alert-success mb-3"><strong>You've got mail!</strong> Check your inbox to activate your subscription.</div>
              <a @click="hidePrompt" class="btn blue text-center" style="font-weight:bold;">OK</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'request_referrer',
    'request_fullpath',
    'form_type',
    'prompt_id'
  ],
  data: function() {
    return {
      showPrompt: false,
      keyUp: false,
      submitting: false,
      finished: false,
      email: null,
      error: null
    }
  },
  mounted: function() {
    setTimeout(function() { this.showPrompt = true }.bind(this), 1000)
  },
  methods: {
    hidePrompt: function() {
      this.showPrompt = false
    },
    dismissPrompt: function() {
      this.showPrompt = false
      if (!this.finished) {
        this.axios.get('/prompts/dismiss.json')
      }
    },
    currentSubscriber: function() {
      this.showPrompt = false
      if (!this.finished) {
        this.axios.get('/prompts/current.json')
      }
    },
    validateEmail: function() {
      var comp = this;
      if (!this.email || this.email.trim() == '') {
        comp.error = 'Please enter a valid email address.';
        return false;
      } else if (this.email && !/[\S]+@[\S]+\.[\S]+/.test(this.email.trim())) {
        comp.error = 'Please enter a valid email address.';
        return false;
      } else {
        var xmlHttp = new XMLHttpRequest();
        var url = 'https://emailvalidation.abstractapi.com/v1/?api_key=b7463b224a8743f596dc4a51778497c0&email=' + this.email;
        let emailResponse;
        xmlHttp.onreadystatechange = function() {
          if(this.readyState === 4) {
            if(this.status === 200) {
                emailResponse = JSON.parse(this.responseText);
                console.log(emailResponse.deliverability);
                if(emailResponse.deliverability === 'DELIVERABLE') {
                    comp.error = null;
                    return true;
                } else {
                    comp.error = 'Please enter a valid email address.';
                    return false;
                }
            } else {
                comp.error = 'Please enter a valid email address.';
                return false;
            }
          }
        }
        xmlHttp.open("GET", url, false); // true for asynchronous
        xmlHttp.send(null);

        if(comp.error !== null)
        {
            return false;
        } else {
            return true;
        }
      }
    },
    clicked: function(e) {
      this.error = null
      if (!this.validateEmail()) {
        return
      }
      this.submitting = true
      this.axios.post('/api/subscriptions.json',
        {
          person: { email: this.email.trim() },
          subscription: {
            utm_source: this.utm_source,
            utm_medium: this.utm_medium,
            utm_campaign: this.utm_campaign,
            request_referrer: this.request_referrer,
            request_fullpath: this.request_fullpath,
            form_type: this.form_type,
            prompt_id: this.prompt_id
          }
        }
      ).then((response) => {
        this.submitting = false
        this.finished = true
        if (typeof(Storage) !== "undefined") {
          localStorage.setItem("user.subscriber", "true")
          localStorage.setItem("user.subscribed_at", Date.now())
        }

        if(response.data.redirect_to) {
            window.location = response.data.redirect_to
        }
      }).catch((error) => {
        this.submitting = false
        if (400 == error.response.status) {
          this.error = error.response.data.errors
        } else if (409 == error.response.status && error.response.data.redirect_to) {
          window.location = error.response.data.redirect_to
        } else {
          console.log(error.response)
        }
      })
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.0s
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}

#subscribe-prompt {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

#subscribe-prompt .container {
    background-image: url(https://img.patriotpost.us/01HRQ1S5PNS9D5M78XYR6NWD9T.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
}
@media only screen and (min-width: 1600px)
{
    #subscribe-prompt .container {
        background-image: url(https://img.patriotpost.us/01HS1M1HYRCGKWN6JDWJRYTZ7N.jpeg);
        background-size: contain;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1279px)
{
    #subscribe-prompt .container {
        background-size: contain;
    }
}
@media only screen and (max-width: 899px)
{
    #subscribe-prompt .container {
        background-image: url(https://img.patriotpost.us/01HRWDSAD1F6SJCSHYX3TVJ32B.jpeg);
        background-size: cover;
        background-repeat: no-repeat;
    }
}
</style>