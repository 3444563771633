<template>
  <div class="box my-4">
    <div class="box-title">
      <h2>Submit a Comment</h2>
    </div>

    <transition name="fade" mode="out-in">
      <div key="not_finished" v-if="!finished" class="box-body">
        <p class="mb-3 p-4 bg-gray-100 border border-gray-200 text-md rounded">To comment about this article, use the social media links above to start a conversation, or use the form below to submit a comment to our editors. We receive hundreds of comments and can only select a few to publish in our Tuesday and Thursday "Reader Comments" sections. Keep it civil, thoughtful, and under 500 characters. (What happened to the old comments forum?  See <a class="underline" href="/about/faq#comments">FAQ</a>)</p>

        <form v-on:submit.prevent="onSubmit">

          <div class="inner">

            <div class="mb-6">
              <label>Name</label>
              <input v-model="comment.name" v-on:change="validateName" type="text" id="comment_name">
              <p v-if="error('comment.name')" class="text-danger">{{ error('comment.name') }}</p>
            </div>

            <div class="mb-6">
              <label>Email</label>
              <input v-if="comment.email !== null " type="text" id="comment_email" :value="comment.email">
              <input v-else v-model="comment.email" type="text" id="comment_email">
              <p v-if="error('comment.email')" class="text-danger">{{ error('comment.email') }}</p>
            </div>

            <div class="mb-6">
              <label>State</label>
              <input v-model="comment.location" v-on:change="validateLocation" type="text" id="comment_location">
              <p v-if="error('comment.location')" class="text-danger">{{ error('comment.location') }}</p>
            </div>

            <div class="mb-6">
              <div class="flex flex-row justify-between">
                <label>Comment</label>
                <transition name="fade">
                  <span v-if="chars > 0" class="text-sm" :class="{ 'text-red-600': (chars > 750) }">Characters: {{ chars }}</span>
                </transition>
              </div>
              <textarea v-model="comment.body" v-on:change="validateBody" rows="10" id="comment_body"></textarea>
              <p v-if="error('comment.body')" class="text-danger">{{ error('comment.body') }}</p>
            </div>

          </div>

          <div class="form-actions">
            <vue-recaptcha
              ref="invisibleRecaptcha"
              sitekey="6LcNsGQUAAAAACPtOOcbnGEY5gT2opDNQ522s5BG"
              size="invisible"
              :loadRecaptchaScript="true"
              @verify="onVerify"
            >
            </vue-recaptcha>
            <button type="submit" class="btn blue mb-6" :disabled="submitting">Submit Comment</button>
          </div>

          <div>
            <small>This site is protected by reCAPTCHA and the Google <a class="underline" href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a class="underline" href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</small>
          </div>

        </form>
      </div>
      <div key="finished" v-else class="box-body">
        <p class="mb-3 p-4 bg-blue-100 border border-blue-200 text-md rounded">Your comment has been received.</p>
      </div>
    </transition>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'

let Every = require('lodash/every')
let Values = require('lodash/values')
let IsNull = require('lodash/isNull')

export default {
  components: {
    VueRecaptcha
  },
  props: ['init'],
  data: function() {
    return {
      finished: false,
      submitting: false,
      post_url: '/comments.json',
      errors: {},
      comment: {
        article_id: null,
        name: "",
        email: "",
        location: "",
        body: ""
      }
    }
  },
  created: function() {
    let json = JSON.parse(this.init)
    this.comment.article_id = json.comment.article_id
    if (typeof(Storage) !== undefined) {
      this.comment.name = localStorage.getItem("comment.name") || ""
      this.comment.email = localStorage.getItem("comment.email") || ""
      this.comment.location = localStorage.getItem("comment.location") || ""
    }

    if (json.comment.person !== undefined) {
        console.log(json.comment.person)
        this.comment.email = json.comment.person[0].email
    }
  },
  computed: {
    chars: function() {
      return this.comment.body.length
    },
    hasErrors: function() {
      if (!Every(Values(this.errors), IsNull)) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    onSubmit: function(response) {
      this.submitting = true
      this.errors = {}
      this.validateName()
      this.validateEmail()
      this.validateLocation()
      this.validateBody()

      if (this.hasErrors) {
        return this.submitFailed()
      } else {
        this.$refs.invisibleRecaptcha.execute()
      }
    },
    onVerify: function(response) {
      this.axios.post(this.post_url,
        {
          'g-recaptcha-response': response,
          comment: this.comment
        }
      ).then((response) => {
        this.submitting = false
        this.finished = true
        if (typeof(Storage) !== "undefined") {
          localStorage.setItem("comment.name", this.comment.name)
          localStorage.setItem("comment.email", this.comment.email)
          localStorage.setItem("comment.location", this.comment.location)
        }
        this.$nextTick(function() {
          var anchor = document.getElementById("v-comment-form-anchor")
          anchor.scrollIntoView({ behavior: 'smooth' })
        })
      }).catch((error) => {
        this.submitting = false
        if (400 == error.response.status) {
          this.hasError('general', error.response.data.errors)
        } else if (409 == error.response.status && error.response.data.redirect_to) {
          window.location = error.response.data.redirect_to
        } else {
          console.log(error.response)
        }
      })
    },
    submitFailed: function() {
      this.submitting = false
      return false
    },
    error: function(key) {
      return this.errors[key]
    },
    hasError: function(key, val) {
      this.$set(this.errors, key, val)
    },
    isValid: function(key) {
      this.$set(this.errors, key, null)
    },
    validateName: function() {
      if (!this.comment.name || this.comment.name.trim() == '') {
        this.hasError('comment.name', 'Name is required.')
      } else {
        this.isValid('comment.name')
      }
    },
    validateEmail: function() {
      if (!this.comment.email || this.comment.email.trim() == '') {
        this.hasError('comment.email', 'Email is required.')
      } else if (this.comment.email && !/[\S]+@[\S]+\.[\S]+/.test(this.comment.email.trim())) {
        this.hasError('comment.email', 'Email must be valid.')
      } else {
        this.isValid('comment.email')
      }
    },
    validateLocation: function() {
      if (!this.comment.location || this.comment.location.trim() == '') {
        this.hasError('comment.location', 'Location is required.')
      } else {
        this.isValid('comment.location')
      }
    },
    validateBody: function() {
      if (!this.comment.body || this.comment.body.trim() == '') {
        this.hasError('comment.body', 'Comment is required.')
      } else if (this.comment.body.length > 750) {
        this.hasError('comment.body', 'Comment must be 750 characters or less.')
      } else {
        this.isValid('comment.body')
      }
    },
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.text-danger {
  @apply
    my-1
    text-sm
    text-red-600;
}
</style>