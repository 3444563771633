/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello World from Webpacker')

import "core-js/stable"
import "regenerator-runtime/runtime"

import 'lazysizes'

require("@rails/ujs").start()

import '../application/style/application.scss'

import Vue from '../vue_rails.js'
import Navigation from '../navigation/navigation.vue'
import CommentForm from '../comment_form/comment_form.vue'
import DonationForm from '../donation_form/donation_form.vue'
import SubscribeForm from '../subscribe_form/subscribe_form.vue'
import SubscribeUlzForm from '../subscribe_ulz_form/subscribe_ulz_form'
import SubscribeShortForm from '../subscribe_short_form/subscribe_short_form.vue'
import SubscribeArticleForm from '../subscribe_article_form/subscribe_article_form.vue'
import SubscribePrompt from '../prompt/subscribe.vue'

function showCommentForm() {
  var container = document.getElementById('comment-container')
  if (!container) return
  container.classList.remove('hidden')
}

document.addEventListener('DOMContentLoaded', () => {
  if ("#post-comment" == document.location.hash) {
    showCommentForm()
  }

  if (document.getElementById('v-navigation')) {
    const navigation = new Vue({
      el: '#v-navigation',
      components: {
        Navigation
      },
    })
  }

  if (document.getElementById('v-comment-form')) {
    var button = document.getElementById('post-comment')
    button.addEventListener('click', function (event) {
      event.preventDefault()
      showCommentForm()
      var anchor = document.getElementById("v-comment-form-anchor")
      anchor.scrollIntoView({ behavior: 'smooth' })
    })
    var button2 = document.getElementById('post-comment2')
    button2.addEventListener('click', function (event) {
      event.preventDefault()
      showCommentForm()
      var anchor = document.getElementById('v-comment-form-anchor')
      anchor.scrollIntoView({ behavior: 'smooth' })
    })
    const comment_form = new Vue({
      el: '#v-comment-form',
      components: {
        CommentForm
      },
    })
  }

  if (document.getElementById('v-donation-form')) {
    const donation_form = new Vue({
      el: '#v-donation-form',
      components: {
        DonationForm
      },
    })
  }

  if (document.getElementById('v-subscribe-form')) {
    const subscribe_form = new Vue({
      el: '#v-subscribe-form',
      components: {
        SubscribeForm
      },
    })
  }

  if (document.getElementById('v-subscribe-ulz-form')) {
    const subscribe_ulz_form = new Vue({
      el: '#v-subscribe-ulz-form',
      components: {
        SubscribeUlzForm
      },
    })
  }

  if (document.getElementById('v-subscribe-short-form')) {
    const subscribe_short_form = new Vue({
      el: '#v-subscribe-short-form',
      components: {
        SubscribeShortForm
      },
    })
  }

  if (document.getElementById('v-subscribe-article-form')) {
    const subscribe_article_form = new Vue({
      el: '#v-subscribe-article-form',
      components: {
        SubscribeArticleForm
      },
    })
  }

  if (document.getElementById('v-subscribe-prompt')) {
    const prompt = new Vue({
      el: '#v-subscribe-prompt',
      components: {
        SubscribePrompt
      },
    })
  }

  var copy_elements = document.querySelectorAll("[data-copy-text]")
  var copy_count

  for (copy_count = 0; copy_count < copy_elements.length; copy_count++) {
    copy_elements[copy_count].addEventListener("click", async(event) => {
      if (!navigator.clipboard) {
        return
      }

      try {
        var copy_value = event.srcElement.getAttribute("data-copy-text")
        await navigator.clipboard.writeText(copy_value)
      } catch (error) {
        console.error("copy failed", error)
      }
    })
  }
})