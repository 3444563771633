<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="!finished" key="form">
        <div class="mb-4 p-4 bg-blue-500 text-white rounded">
          <div class="mb-3 py-1 text-center text-base">
            <span class="block md:inline lg:block xl:inline text-lg lg:text-xl xl:text-lg font-extrabold">Want more articles like this one in your inbox?</span>
            <span class="block md:inline lg:block xl:inline">Subscribe to <cite>The Patriot Post</cite> today! It's <strong>Right</strong>. It's <strong>Free</strong>.</span>
          </div>
          <div key="form" v-if="!finished">
            <div class="input-group border-0">
              <input v-model="email" v-on:keyup="keyUp = true" v-on:change="validateEmail" type="text" placeholder="Email address">
              <button @click="clicked" class="text-base bg-green-500 hover:bg-green-600">
                Subscribe
              </button>
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <div v-if="error" key="error" class="alert alert-error m-0 mt-3 px-3 py-2 text-sm">{{ error }}</div>
          </transition>
        </div>
      </div>
      <div v-else key="success" class="alert alert-success mb-4 p-4 text-center">
        <div class="mb-3 font-bold text-xl"><span class="drop-shadow">You've got mail!</span></div>
        <div class="text-lg">Check your inbox to activate your subscription.</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'request_referrer',
    'request_fullpath',
    'form_type',
    'article_id'
  ],
  data: function() {
    return {
      keyUp: false,
      submitting: false,
      finished: false,
      email: null,
      error: null
    }
  },
  methods: {
    validateEmail: function() {
      var comp = this;
      if (!this.email || this.email.trim() == '') {
        comp.error = 'Please enter a valid email address.';
        return false;
      } else if (this.email && !/[\S]+@[\S]+\.[\S]+/.test(this.email.trim())) {
        comp.error = 'Please enter a valid email address.';
        return false;
      } else {
        var xmlHttp = new XMLHttpRequest();
        var url = 'https://emailvalidation.abstractapi.com/v1/?api_key=b7463b224a8743f596dc4a51778497c0&email=' + this.email;
        let emailResponse;
        xmlHttp.onreadystatechange = function() {
          if(this.readyState === 4) {
            if(this.status === 200) {
                emailResponse = JSON.parse(this.responseText);
                console.log(emailResponse.deliverability);
                if(emailResponse.deliverability === 'DELIVERABLE') {
                    comp.error = null;
                    return true;
                } else {
                    comp.error = 'Please enter a valid email address.';
                    return false;
                }
            } else {
                comp.error = 'Please enter a valid email address.';
                return false;
            }
          }
        }
        xmlHttp.open("GET", url, false); // true for asynchronous
        xmlHttp.send(null);

        if(comp.error !== null)
        {
            return false;
        } else {
            return true;
        }
      }
    },
    clicked: function(e) {
      this.error = null;
      if (!this.validateEmail()) {
        return
      }
      this.submitting = true
      this.axios.post('/api/subscriptions.json',
        {
          person: { email: this.email.trim() },
          subscription: {
            utm_source: this.utm_source,
            utm_medium: this.utm_medium,
            utm_campaign: this.utm_campaign,
            request_referrer: this.request_referrer,
            request_fullpath: this.request_fullpath,
            form_type: this.form_type,
            article_id: this.article_id
          }
        }
      ).then((response) => {
        this.submitting = false
        this.finished = true
        if (typeof(Storage) !== "undefined") {
          localStorage.setItem("user.subscriber", "true")
          localStorage.setItem("user.subscribed_at", Date.now())
        }

        if(response.data.redirect_to)
        {
            window.location = response.data.redirect_to;
        }
      }).catch((error) => {
        this.submitting = false
        if (400 == error.response.status) {
          this.error = error.response.data.errors
        } else if (409 == error.response.status && error.response.data.redirect_to) {
          window.location = error.response.data.redirect_to
        } else {
          console.log(error.response)
        }
      });
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

.input-group,
.input-group:hover {
  @apply shadow;
}
</style>