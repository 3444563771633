<template>
<div class="inline-block relative w-full">
  <select :value="value" @change="$emit('change', $event.target.value)" :id="elId" class="block appearance-none w-full bg-white text-gray-700 border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" :class="{ 'error': error }">
    <option v-if="blank" :value="blank_value">{{ blank_text }}</option>
    <option v-for="option in options" :value="option[0]">{{ option[1] }}</option>
  </select>
  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
  </div>
</div>
</template>

<script>
export default {
  props: ['options', 'value', 'error', 'id', 'blank', 'blank_value', 'blank_text'],
  data: function () {
    return {
      // Set the element ID randomly if not passed in via props
      elId: (this.id || Math.random().toString(36).substr(2))
    }
  }
}
</script>