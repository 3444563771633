<template>
<div class="fixed hidden lg:block lg:container lg:shadow-md -mb-16 z-50">
  <ul class="nav nav-lg text-sm bg-blue-900 border-white border-b-2">
    <li class="static">
      <a href="/">HOME</a>
    </li>
    <li class="dropdown"
      @click="clicked('about')"
      @clickout="clickedOut('about')"
      :class="{ 'clicked': isClicked('about') }"
    >
      <div class="heading">
        <span>ABOUT</span>
        <img src="/images/down-arrow.svg" aria-hidden="true">
      </div>
      <ul v-if="isClicked('about')" class="menu">
        <li class="uppercase text-xs font-semibold pt-2 pb-2 px-5 text-gray-600">About The Patriot Post</li>
        <li><a href="/about">Our Mission</a></li>
        <li><a href="/about/more">Vision</a></li>
        <li><a href="/about/mark-alexander">Mark Alexander</a></li>
        <li><a href="/about/team">Our Team</a></li>
        <li><a href="/about/comments">Comments</a></li>
        <li class="uppercase text-xs font-semibold pt-4 pb-2 px-5 text-gray-600 border-t">Legal Notices</li>
        <li><a href="/about/terms">Terms of Use</a></li>
        <li><a href="/about/privacy">Privacy Policy</a></li>
        <li><a href="/about/faq">FAQs</a></li>
      </ul>
    </li>
    <li class="dropdown"
      @click="clicked('sections')"
      @clickout="clickedOut('sections')"
      :class="{ 'clicked': isClicked('sections') }"
    >
      <div class="heading">
        <span>ARCHIVES</span>
        <img src="/images/down-arrow.svg" aria-hidden="true">
      </div>
      <ul v-if="isClicked('sections')" class="menu">
        <li><a href="/alexander">Alexander's Columns</a></li>
        <li><a href="/articles">Right Analysis</a></li>
        <li><a href="/headlines">Headlines</a></li>
        <li><a href="/opinion">Right Opinion</a></li>
        <li><a href="/videos">Videos</a></li>
        <li><a href="/memes">Memesters Union</a></li>
        <li><a href="/cartoons">Cartoons</a></li>
        <li><a href="/podcasts/the-pop-culture-contrarian">Podcasts</a></li>
      </ul>
    </li>
    <li class="dropdown subscription"
      @click="clicked('subscription')"
      @clickout="clickedOut('subscription')"
      :class="{ 'clicked': isClicked('subscription') }"
    >
      <div class="heading">
        <span>SUBSCRIPTION</span>
        <img src="/images/down-arrow.svg" aria-hidden="true">
      </div>
      <ul v-if="isClicked('subscription')" class="menu">
        <li class="uppercase text-xs font-semibold pt-3 pb-2 px-5 text-gray-600">What would you like to do?</li>
        <li><a href="/subscription/new">I want to subscribe.</a></li>
        <li><a href="/subscription/login?page_action=email">I want to change my email address.</a></li>
        <li><a href="/subscription/login?page_action=list">I want to change what I receive.</a></li>
        <li><a href="/subscription/login?page_action=unsubscribe">I want to unsubscribe.</a></li>
        <li><a href="/about/faq#subscription">I'm not receiving my subscription.</a></li>
      </ul>
    </li>
    <li class="static support"><a href="/donate.html">SUPPORT US</a></li>
    <li class="dropdown"
      @click="clicked('resources')"
      @clickout="clickedOut('resources')"
      :class="{ 'clicked': isClicked('resources') }"
    >
      <div class="heading">
        <span>RESOURCES</span>
        <img src="/images/down-arrow.svg" aria-hidden="true">
      </div>
      <ul v-if="isClicked('resources')" class="menu">
        <li><a href="/documents">Historic Documents</a></li>
        <li><a href="/references">Reference Pages</a></li>
        <li><a href="/quotes">Founders' Quote Database</a></li>
        <li><a href="/reagan">Reagan 2020</a></li>
        <li><a href="/flag">The American Flag</a></li>
        <li><a href="/eprpc">Be Prepared (EPRPC)</a></li>
        <li><a href="/petitions">Patriot Petitions</a></li>
        <li class="uppercase text-xs font-semibold text-gray-600 pt-4 pb-2 px-5 text-gray-600 border-t">External Links</li>
        <li><a href="https://patriotpostshop.com">The Patriot Post Shop</a></li>
        <li><a href="https://patriotfoundationtrust.org">The Patriot Foundation Trust</a></li>
      </ul>
    </li>
    <li class="static">
      <a href="https://patriotpostshop.com">SHOP</a>
    </li>
    <li class="static">
      <a href="/search">
        <img class="inline-block h-2 pr-1 -mt-1" src="/images/search.svg">
        SEARCH
      </a>
    </li>
  </ul>
</div>
</template>

<script>
var clickoutEvent = require("clickout-event")

export default {
  data: function() {
    return {
      active: null
    }
  },
  methods: {
    clicked: function(name) {
      if (this.active == name) {
        this.active = null
      } else {
        this.active = name
      }
    },
    isClicked: function(name) {
      return this.active == name
    },
    clickedOut: function(name) {
      if (this.active == name) {
        this.active = null
      }
    }
  }
}
</script>

<style scoped>
li,
span {
  cursor: pointer;
}

ul.nav {
  @apply flex justify-end text-white;
}

ul.nav-lg li.static,
ul.nav-lg li.dropdown {
  @apply border-l border-gray-600;
}

ul.nav-lg li.dropdown.subscription {
  @apply bg-blue-600;
}

ul.nav-lg li.static.support {
  @apply bg-green-600;
}

ul.nav-lg li.static a,
ul.nav-lg li.dropdown div.heading {
  @apply block px-5 py-4;
}

ul.nav li.dropdown div.heading {
  @apply flex;
}

ul.nav li.dropdown div.heading img {
  @apply self-center ml-2;
  width: 10px;
  min-width: 10px;
}

ul.nav li.dropdown ul.menu {
  @apply absolute bg-white text-blue-900 px-0 py-2 m-0 border border-t-0 border-gray-600 shadow;
}

ul.nav li.dropdown ul.menu li a {
  @apply block w-auto px-5 py-2 text-gray-700;
}

ul.nav li.dropdown ul.menu li a:hover {
  @apply text-gray-900 bg-gray-300;
}

ul.nav li.static a:hover,
ul.nav li.dropdown div.heading:hover,
ul.nav li.dropdown.clicked {
  @apply bg-blue-700;
}

ul.nav li.static.support a:hover {
  @apply bg-green-700;
}
</style>