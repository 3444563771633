<template>
<div class="form-group">
  <label :for="elId"><slot></slot></label>
  <div class="donate-input-group prepend">
    <div class="donate-input-addon">
      <span>$</span>
    </div>
    <input
      type="text"
      :value="value"
      @change="$emit('change', $event.target.value)"
      placeholder="Enter amount"
      :id="elId"
      class="donate-input"
      :class="{ 'error': error }"
    >
  </div>
  <p v-if="error" class="text-danger">{{ error }}</p>
</div>
</template>

<script>
export default {
  props: ['value', 'error', 'id'],
  data: function () {
    return {
      // Set the element ID randomly if not passed in via props
      elId: (this.id || Math.random().toString(36).substr(2))
    }
  }
}
</script>
