<template>
<div>
  <div class="fixed w-full lg:hidden bg-blue-900 shadow-md z-40" style="height:48px;">
    <div class="relative h-full flex flex-row items-center justify-between md:justify-end">
      <a class="px-2 py-1 h-full flex flex-row items-center md:hidden" href="/">
        <img class="h-8 sm:h-full mr-2" src="/images/seal.svg">
        <img class="h-8 sm:h-full" src="/images/the-patriot-post-white.svg">
      </a>
      <ul class="h-full flex flex-row items-center">
        <li class="h-full border-l border-gray-600 text-white">
          <a href="/search" class="h-full flex flex-row items-center px-4 text-sm uppercase hover:bg-blue-700">
            <img class="block h-3 sm:mr-2" src="/images/search.svg">
            <span class="hidden sm:block">Search</span>
          </a>
        </li>
        <li
          class="h-full border-l border-gray-600 text-white"
          @click="clicked"
        >
          <a :class="{ 'bg-blue-700': showOverlay }" class="h-full flex flex-row items-center px-4 text-sm uppercase hover:bg-blue-700">
            <img class="block h-3 sm:mr-2" src="/images/hamburger.svg">
            <span class="hidden sm:block">Menu</span>
          </a>
          <transition name="overlay-fade" @after-enter="showMenu = true">
            <div v-if="showOverlay" class="fixed bottom-0 left-0 right-0 bg-black bg-opacity-50 overflow-hidden z-30" style="top:48px;">
              <transition name="menu-slide-fade" @after-leave="showOverlay = false">
                <div v-if="showMenu" class="flex flex-row h-full">
                  <div class="flex-grow bg-transparent">
                    <!-- Window -->
                  </div>
                  <div @click.stop class="h-full w-2/3 md:w-1/2 px-6 py-3 text-gray-900 bg-white border-l border-gray-600 shadow-md overflow-y-scroll">
                    <ul class="menu">
                      <li><a class="block my-3 px-4 py-3 bg-gradient-to-b from-blue-500 to-blue-600 rounded-lg text-white shadow hover:bg-gradient-to-b hover:from-blue-700 hover:to-blue-800" href="/subscription/new">Subscribe</a></li>
                      <li><a href="/donation/new" class="block my-3 px-4 py-3 bg-gradient-to-b from-green-500 to-green-600 mt-2 rounded-lg text-white shadow hover:bg-gradient-to-b hover:from-green-700 hover:to-green-800">Support Us</a></li>
                      <li
                        class="title"
                        @click="sections = !sections"
                      >
                        <a>
                          Archives
                          <span v-if="!sections" class="">+</span>
                          <span v-if="sections" class="">-</span>
                        </a>
                        <transition name="submenu-slide-fade">
                          <ul v-if="sections" @click.stop class="submenu">
                            <li><a href="/articles">Analysis</a></li>
                            <li><a href="/alexander">Alexander's Columns</a></li>
                            <li><a href="/opinion">Opinion</a></li>
                            <li><a href="/memes">Memes</a></li>
                            <li><a href="/cartoons">Cartoons</a></li>
                            <li><a href="/headlines">Headlines</a></li>
                            <li><a href="/videos">Videos</a></li>
                            <li><a href="/podcasts/the-pop-culture-contrarian">Podcasts</a></li>
                          </ul>
                        </transition>
                      </li>
                      <li
                        class="title"
                        @click="about = !about"
                      >
                        <a>
                          About
                          <span v-if="!about" class="">+</span>
                          <span v-if="about" class="">-</span>
                        </a>
                        <transition name="submenu-slide-fade">
                          <ul v-if="about" @click.stop class="submenu">
                            <li><a href="/about">Our Mission</a></li>
                            <li><a href="/about/more">Vision</a></li>
                            <li><a href="/about/mark-alexander">Mark Alexander</a></li>
                            <li><a href="/about/team">Our Team</a></li>
                            <li><a href="/about/comments">Comments</a></li>
                            <li><a href="/about/terms">Terms of Use</a></li>
                            <li><a href="/about/privacy">Privacy Policy</a></li>
                            <li><a href="/about/faq">FAQs</a></li>
                          </ul>
                        </transition>
                      </li>
                      <li
                        class="title"
                        @click="resources = !resources"
                      >
                        <a>
                          Resources
                          <span v-if="!resources" class="">+</span>
                          <span v-if="resources" class="">-</span>
                        </a>
                        <transition name="submenu-slide-fade">
                          <ul v-if="resources" @click.stop class="submenu">
                            <li><a href="/documents">Historic Documents</a></li>
                            <li><a href="/references">Reference Pages</a></li>
                            <li><a href="/quotes">Founders' Quote Database</a></li>
                            <li><a href="/reagan">Reagan 2020</a></li>
                            <li><a href="/flag">The American Flag</a></li>
                            <li><a href="/eprpc">Be Prepared (EPRPC)</a></li>
                            <li><a href="/petitions">Patriot Petitions</a></li>
                            <li><a href="https://patriotfoundationtrust.org">The Patriot Foundation Trust</a></li>
                          </ul>
                        </transition>
                      </li>
                      <li class="title"><a href="/subscription/login?page_action=subscription">Subscription</a></li>
                      <li class="title"><a href="https://patriotpostshop.com">Shop</a></li>
                    </ul>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>

<script>
// var clickoutEvent = require("clickout-event")

export default {
  data: function() {
    return {
      showOverlay: false,
      showMenu: false,
      about: false,
      sections: true,
      resources: false
    }
  },
  methods: {
    clicked: function() {
      if (this.showOverlay) {
        this.showMenu = false
      } else {
        this.showOverlay = true;
      }
    },
  }
}
</script>

<style scoped>
.overlay-fade-enter-active,
.overlay-fade-leave-active {
  transition: opacity .1s;
}

.overlay-fade-enter,
.overlay-fade-leave-to {
  opacity: 0;
}

.menu-slide-fade-enter-active,
.menu-slide-fade-leave-active {
  transition: all .5s ease;
}

.menu-slide-fade-enter,
.menu-slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.submenu-slide-fade-enter-active,
.submenu-slide-fade-leave-active {
  transition: all .25s ease;
}

.submenu-slide-fade-enter,
.submenu-slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}


.title > a {
  @apply
    py-2
    flex
    flex-row
    justify-between
    items-center
    text-lg
    cursor-pointer
    z-40;
}

.submenu {
  @apply
    block
    pl-4
    border-l-2
    border-gray-300
    text-base
    z-30;
}

.submenu li a {
  @apply
    block
    py-1;
}
</style>