<template>
<nav>
  <mobile-navigation/>
  <main-navigation/>
</nav>
</template>

<script>
import MainNavigation from './main_navigation.vue'
import MobileNavigation from './mobile_navigation.vue'

export default {
  components: {
    MainNavigation,
    MobileNavigation
  }
}
</script>