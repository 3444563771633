<template>
<section>
  <div class="gift-checkbox">
    <label>
      <input type="checkbox" :value="checkbox" @click="$emit('checkbox-click', $event.target.checked)">
      Yes, I would like to receive a free gift with my donation
    </label>
  </div>
  <transition name="fade">
    <div v-if="checkbox" class="gift-list">
      <label>Please select a gift:</label>
      <div class="gift-options">
        <button v-for="gift in gifts" :key="gift.name" :value="gift.name" @click="$emit('button-click', gift)" :class="{ selected: gift.name == selected }" class="donate-btn">
          <img class="gift-image" :src="gift.img_src">
          <div class="gift-name">{{ gift.name }}</div>
        </button>
      </div>
    </div>
  </transition>
  <p v-if="error" class="text-danger">{{ error }}</p>
</section>
</template>

<script>
export default {
  props: ['gifts', 'checkbox', 'selected', 'error'],
  data: function() {
    return {}
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.gift-checkbox {
  margin-top: 4px;
}

.gift-checkbox label {
  margin-bottom: 0;
}

.gift-list {
  @apply
    pt-6;
}

.gift-options {
  @apply
    flex
    items-stretch
    content-center;
}

.gift-options .donate-btn {
  @apply
    mx-1
    mb-2
    p-4
    rounded-lg
    bg-gray-300
    text-center;
}

.gift-options .donate-btn:first-child {
  @apply
    ml-0;
}

.gift-options .donate-btn:last-child {
  @apply
    mr-0;
}

.donate-btn img {
  display: block;
  margin: 0 auto;
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

.donate-btn.selected {
  @apply
    text-white
    bg-patriot-blue;
}

.donate-btn.selected img {
  -webkit-filter: grayscale(0);
  filter: none;
}

.donate-btn div {
  padding-top: 10px;
}

@media (max-width: 768px) {
  .gift-options {
    @apply
      flex-col;
  }

  .gift-options .donate-btn {
    @apply
      flex
      w-full
      mx-0
      items-center;
  }

  .gift-options .donate-btn img {
    @apply
      flex-initial;
    width: 75px;
  }

  .gift-options .donate-btn div {
    @apply
      flex-1;
    padding-top: 0;
  }
}
</style>
