<template>
  <button :value="value" @click="$emit('click', value)" :class="{ selected: value == selected }" class="donate-btn">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ['value', 'selected'],
  data: function () {
    return {}
  }
}
</script>

<style scoped>
.donate-btn {
  @apply
    px-1
    py-4
    text-sm !important;
}

@screen md {
  .donate-btn {
    @apply
      text-base !important;
  }
}
</style>