<template>
<div class="form-group">
  <label :for="elId" :class="labelClass"><slot>&nbsp;</slot></label>
  <input
    type="text"
    :value="value"
    @input="$emit('input', $event.target.value)"
    @change="$emit('change')"
    :id="elId"
    class="donate-input"
    :class="{ error: error }"
  >
  <p v-if="error" class="text-danger">{{ error }}</p>
</div>
</template>

<script>
export default {
  props: ['value', 'error', 'id', 'labelClass'],
  data: function () {
    return {
      // Set the element ID randomly if not passed in via props
      elId: (this.id || Math.random().toString(36).substr(2))
    }
  }
}
</script>
